import { Fragment } from 'react'

import styled from '@emotion/styled'
import Link from 'next/link'
import { useSelector } from 'react-redux'

import { getWalledGarden } from '@/redux/cookies/selectors'
import { breakpoint } from '@/theme'
import ROUTES from '@helpers/routes'
import Logo from '@microcomponents/icons/logo'
import { isLoggedIn } from '@redux/auth/selectors'

import useFooterRules from './useFooterRules'

export default function Footer() {
  const isWalledOff = useSelector(getWalledGarden)
  const isLoggedInSelector = useSelector(isLoggedIn)
  const getCurrentYr = () => new Date().getFullYear()
  const footerWarningMessages = useFooterRules()

  return (
    <Container>
      {isWalledOff ? (
        <UnlinkedLogo fill="#fff" />
      ) : (
        <LogoLink href={ROUTES.ROOT}>
          <Logo fill="#fff" />
        </LogoLink>
      )}
      <Content>
        <Section>
          <Copy>
            Copyright © {getCurrentYr() > 2024 ? `2024-${getCurrentYr()}` : getCurrentYr()} Eaze Inc. All rights
            reserved.
          </Copy>
          {footerWarningMessages &&
            footerWarningMessages.map((warningObj, index) => (
              <Copy key={index} data-test={`warningMsg-${index}`}>
                {warningObj.message}
              </Copy>
            ))}
        </Section>
        {!isWalledOff && (
          <Fragment>
            <Section>
              {!isLoggedInSelector && (
                <Fragment>
                  <FooterLink href={ROUTES.SIGNUP}>Sign Up</FooterLink>
                  <FooterLink href={ROUTES.LOGIN}>Log In</FooterLink>
                </Fragment>
              )}
              <FooterLink href="https://help.eaze.com/" target="_blank" rel="noopener noreferrer">
                Support
              </FooterLink>
              <FooterLink href={ROUTES.CONTACT}>Contact Us</FooterLink>
            </Section>
            <Section>
              <FooterLink href={ROUTES.ABOUT}>About Us</FooterLink>
              <FooterLink href={ROUTES.BLOG}>Blog</FooterLink>
              <FooterLink href={ROUTES.PRESS}>Press</FooterLink>
              <FooterLink href={ROUTES.CAREERS}>Careers</FooterLink>
            </Section>
            <Section>
              <FooterLink href={ROUTES.WEED_DELIVERY}>Delivery Locations</FooterLink>
              <FooterLink href="https://drive.eaze.com" target="_blank" rel="noopener noreferrer">
                Become a Driver
              </FooterLink>
              <FooterLink href={ROUTES.LICENSED_RETAILERS}>Licensed Retailers</FooterLink>
              <FooterLink href={ROUTES.REFERRAL_PROGRAM_RULES}>Referral Program Rules</FooterLink>
            </Section>
            <Section>
              <FooterLink href={ROUTES.TERMS_OF_SERVICE}>Terms of Service</FooterLink>
              <FooterLink href={ROUTES.PRIVACY_POLICY}>Privacy Policy</FooterLink>
              <FooterLink href={ROUTES.COOKIE_MANAGER}>Do Not Sell My Info</FooterLink>
            </Section>
          </Fragment>
        )}
      </Content>
    </Container>
  )
}

const cols = '5'

const Container = styled.footer`
  background-color: #353034;
  padding: 5rem 5rem 2rem;

  @media (max-width: ${breakpoint.max.md}px) {
    padding: 2rem 2rem 9rem;
  }
`

const Content = styled.div`
  max-width: ${breakpoint.min.xxl}px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${cols}, 1fr);
  grid-column-gap: 4rem;
  align-items: start;

  & a,
  & a:link,
  & a:visited,
  & a:active {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.typography.font.sim};
    text-decoration: none;
  }

  @media (max-width: ${breakpoint.max.md}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`

const UnlinkedLogo = styled(Logo)`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
`

const LogoLink = styled(Link)`
  display: block;
  max-width: ${breakpoint.min.xxl}px;
  margin: 0 auto ${({ theme }) => theme.spacing.large}px;
`

const Section = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${breakpoint.max.md}px) {
    margin: 0;

    &:first-of-type {
      margin-bottom: ${({ theme }) => theme.spacing.xxl}px;
    }
  }
`

const FooterLink = styled(Link)`
  text-transform: lowercase;
  margin-bottom: 2rem;

  @media (max-width: ${breakpoint.max.md}px) {
    margin-bottom: 1rem;
  }
`

const Copy = styled.p`
  flex: 1.5 0;
  font-family: ${({ theme }) => theme.typography.font.sim};
  font-size: 12px;
  color: ${({ theme }) => theme.colors.textDisabled};
  margin: 0;
`
